import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ApiGetknowledge,ApiGetvw_field_study } from '../../../redux/actions/master'

import Button from '../../../components/form/button/Button'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import SearchText from '../../../components/form/SearchText'
import Checkbox from '../../../components/form/Checkbox'
import PaginationV2 from '../../../components/common/PaginationV2'
import TextField from '../../../components/form/TextField'

import { IoMdClose } from 'react-icons/io'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { HiOutlineLightBulb } from "react-icons/hi";


const Div = styled.div`
    padding: 24px 0px;
    width: ${window.innerWidth * 0.75}px;
    /*height: ${window.innerHeight * 0.65}px;*/
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color : #101828;
    .header_confirm{
        display: flex;
        justify-content: space-between;
        padding: 0px 24px;
    }
    .icon-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal_text_bold{
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        color : #101828;
    }
    .modal_text_normal{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color : #475467;
    }
    .border_header {
        border-bottom: 1px solid rgb(234, 236, 240);
    }
    .css_magin_top_16{
        margin-top: 16px;
    }
    .css_magin_top_12{
        margin-top: 12px;
    }
    .icon {
        cursor: pointer;
    }
    .box_content{
        padding: 0px 24px;
    }
    .group_status{
        display: flex;
        gap: 10px;
    }
    .box_engineer{
        .status-badge {
          width: fit-content;
          padding: 2px 6px;
          text-align: center;
          font-weight: 500;
          border-radius: 16px;
          color: #C11574;
          background: #FDF2FA;
          border: 1px #FCCEEE solid;
          .status {
            font-size: 0.6rem;
            margin-right: 0.2rem;
            color: #C11574;    
          }
        }
    }

    .status-badge-2 {
        width: fit-content;
        padding: 2px 6px;
        text-align: center;
        font-weight: 500;
        border-radius: 16px;
        color: #475467;
        background: #f5f5f4;
        border: 1px #e7e5e4 solid;
      
        .status {
          font-size: 0.6rem;
          margin-right: 0.2rem;
          color: #475467;
        }
    }

    .content_box {
        display: flex;
        /*align-items: flex-start;*/
        align-items: center;
        gap: 10px;
    }
    .topic_text{
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        color: #344054;
    }
    .dialog-button {
        padding: 0px 24px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;

        button {
            justify-content: center;
        }
    }
    .css_search_text{
        min-width : 300px;
    }

    .status-badge-code-tag {
        width: fit-content;
        padding: 4px 4px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        text-align: center;
        border-radius: 16px;
        color: #344054;
        background: #F9FAFB;
        border: 1px #EAECF0 solid;
        display: flex;
        gap: 10px;
        align-items: center;
    }
    .status-badge-code-tag-sel {
        width: fit-content;
        padding: 2px 6px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        text-align: center;
        border-radius: 16px;
        color: #6941C6;
        background: #F9F5FF;
        border: 1px #E9D7FE solid;
        display: flex;
        gap: 2px;
        align-items: center;
    }
`

const DivKnowledge = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap : 10px;
  margin-top : 0px;

.status-badge {
    width: fit-content;
    padding: 4px 12px;
    
    display: flex;
    align-items: center;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    border-radius: 16px;
    color: #363F72;
    background: #F8F9FC;
    border: 1px #D5D9EB solid;

    cursor: pointer;


    &.active {
        color: #FFFFFF;
        background: #3E4784;
        border: 1px #3E4784 solid;
    }
  }
`

const DivTable = styled.div`
  width: 100%;
  overflow-x: auto;
  .text_css{
    min-width: 200px;
  }
  .text-bold {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #101828;
  }
  
  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: var(--Shadow-xs);

    tr {
      border: 1px solid var(--Gray-200);
    }

    th {
      padding: 16px 14px;
      text-align: left;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: var(--Gray-600);
      white-space: nowrap;
      background: var(--Base-White);
    }
    td {
      padding: 16px 14px;
      text-align: left;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: #344054;
      white-space: nowrap;
      background: var(--Base-White);
    }

    th {
      padding: 12x 14px;
      font-weight: 500;
      background: var(--Gray-50);
    }

    tr:hover {
      td {
        background: var(--Table-Bg-Hover);
        color: var(--Table-Font-Hover);
      }
    }
  }

  .content-wrapper {
    text-align: center;
  }

  .pagination {
    /*margin: 30px 24px;*/
    margin-top: 20px;
  }

  .cursor_pointer {
    cursor: pointer;
  }
  .cancel-activity {
    color: var(--COE-Main-CI);
    font-weight: 700;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .col-checkbox {
    width : 5%
  }
  .col-no {
    width : 7%
  }
  .col-knowledge {
    width : 20%
  }
  .col-field_study {
    width : 68%
  }
`

const ModalKnowledge = ({ onClose, onSubmit,...props  }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [KnowledgeActive, setKnowledgeActive] = useState(-1)
    const [KnowledgeList, setKnowledgeList] = useState([])

    useEffect(() => {
        const filter = {
            skip: 0,
            limit: 1000,
        }
        dispatch(ApiGetknowledge(filter)).then(({ type,data_info }) => {
            if(type.endsWith('_SUCCESS')){
                if(data_info.data.length > 0){
                    const newKnowledge = data_info.data?.map((v) => ({
                        value: v?.id,
                        text: v?.name_th_th,
                        active : false
                    }))
                    setKnowledgeList([{value : -1,text : 'ทั้งหมด',active : true},...newKnowledge])
                }

            }
        })
    }, [dispatch])

    const [TagList, setTagList] = useState([])

    const [filterName, setfilterName] = useState('')
    const limit_data = 5;
    const [total, settotal] = useState(0)
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [TableCellData, setTableCellData] = useState([])
    const TableColumnData = [
      {
        Header: '',
        accessor: 'checkbox',
        disableSortBy: false,
      },
      {
          Header: 'ลำดับ',
          accessor: 'no',
          disableSortBy: false,
      },
      {
          Header: 'หัวข้อหลัก',
          accessor: 'knowledge',
          disableSortBy: false,
      },
      {
        Header: 'สาขา/วิชา',
        accessor: 'field_study',
        disableSortBy: false,
      },
    ]

    const fetchData = useCallback(
        async (filter) => {
    
          dispatch(ApiGetvw_field_study(filter)).then(({ type,data_info }) => {
            if(type.endsWith('_SUCCESS')){
              if (data_info.total > 0) {
                const totalPage = Math.ceil(data_info.total / limit_data)
                settotal(data_info.total)
                setTotalPages(totalPage)
                setTableCellData(data_info.data)
              }else{
                settotal(0);
                setPage(0);
                setTotalPages(0);
                setTableCellData([]);
              }
             
            }
          })
    
        },
        [dispatch]
      )

    useEffect(() => {
        const filter = {
            skip: 0,
            limit: limit_data,
            knowledge_id : KnowledgeActive,
            name : filterName
        }
        fetchData(filter)
    }, [fetchData, limit_data])
   
    const onClearfilterName = () => {
        setfilterName('');
    }
    const handleKnowledge = (data) => {
        let tmpList = [...KnowledgeList]
        for (let i = 0; i < tmpList.length; i++) {
          if (tmpList[i].value == data.value) {
            tmpList[i].active = true
            setKnowledgeActive(tmpList[i].value)
            setfilterName('');
            const filter = {
                skip: 0,
                limit: limit_data,
                knowledge_id: tmpList[i].value,
            }
            fetchData(filter)
            setPage(1)
          } else {
            tmpList[i].active = false
          }
        }
        setKnowledgeList([...tmpList])
    }

    const onSearch = () => {
        const filter = {
            skip: 0,
            limit: limit_data,
            knowledge_id : KnowledgeActive,
            name : filterName
        }
        fetchData(filter)
        setPage(1)
    }

    const onChangePaginationAttendeeAttend = async (value) => {
        const filter = {
            skip: (value.page  - 1) * limit_data, 
            limit: limit_data,
            knowledge_id : KnowledgeActive,
            name : filterName
        }
        fetchData(filter)
        setPage(value.page)
    }

    const handleChangeCheckBoxApprove = (e,v_inp) => {  
        if(TagList.filter((v) => v.value == v_inp.id).length > 0){
            setTagList(TagList.filter((v) => v.value !== v_inp.id))
        }else{
            const new_data = {
                value : v_inp.id,
                knowledge_th : v_inp.knowledge_th,
                field_study_th : v_inp.field_study_th
            }
            setTagList(current => [...current, new_data]); 
        }    
      }

      const ProcessApi = () => {
        onSubmit(TagList)
     }

    return (
        <Div {...props}>
            <div className="header_confirm">
                <div className="content_box">
                    <div className="icon-content"><HiOutlineLightBulb size={20}   /></div>
                    <div className="modal_text_bold">
                        ติดแท็กความรู้
                    </div>
                </div>
                <div>
                    <IoMdClose size={20} className="icon" onClick={onClose} />
                </div>
            </div>
            <div className="css_magin_top_16"></div>
            <div className="border_header"></div>
            <div className="css_magin_top_16"></div>
            <div className="box_content">
                <div className="topic_text">หัวข้อหลัก *</div>
                <div className="css_magin_top_12"></div>
                {KnowledgeList?.length > 0  && ( 
                    <DivKnowledge>
                        {KnowledgeList?.map((item, index) => (                  
                            <div className={item.active ? 'status-badge active' : 'status-badge'} onClick={() => handleKnowledge(item)}>
                                {item.text}
                            </div>
                        ))} 
                    </DivKnowledge>
                )}
                <div className="css_magin_top_16"></div>
                <div className="topic_text">ค้นหาชื่อสาขา/วิชา</div>
                <div className="css_magin_top_12"></div>
                <div className="content_box">
                    <div>
                        <SearchText
                            className="css_search_text"
                            placeholder="พิมพ์คำค้นหา"
                            value={filterName}
                            onChange={(e) => setfilterName(e.target.value)}
                            onClear={onClearfilterName}
                        />
                    </div>
                    <div>
                        <Button onClick={onSearch} >
                            ค้นหา
                        </Button>
                    </div>
                </div>
                <div className="css_magin_top_16"></div>
                <div className="content_box">
                    <div className="topic_text">ติ๊กเพื่อเลือกรายการ (เลือกได้มากกว่า 1) </div>
                    <div className="status-badge-code-tag">                   
                        <div className="status-badge-code-tag-sel">
                            เลือกแล้ว
                        </div>
                        <div>{TagList.length + ' รายการ'}</div>
                    </div>
                </div>
                <div className="css_magin_top_12"></div>
                <DivTable>
                    <table >
                        <thead>
                            <tr >
                                {TableColumnData.map((column) => (
                                    <th
                                        key={column.accessor}
                                        className={`col-${column.accessor}`}
                                    >
                                        <div>{column.Header ? column.Header : ''}</div>                                           
                                    </th>
                                ))}
                            </tr>
                            {TableCellData?.map((v, index) => (
                                <tr key={index}>
                                    <td >
                                        <Checkbox 
                                            key={v.id} 
                                            checked={TagList.filter((v_check) => v_check.value == v.id).length > 0 ? true : false} 
                                            onChange={(e) => handleChangeCheckBoxApprove(e,v)} 
                                        />
                                    </td>
                                    <td >
                                        {limit_data * page - ((limit_data - 1) - index)}
                                    </td>
                                    <td >
                                        <div className="text-bold" >
                                            {v.knowledge_th}                               
                                        </div>
                                    </td>  
                                    <td >
                                        {v.field_study_th}   
                                    </td>
                                </tr>
                            ))}
                        </thead>
                    </table>
                    <PaginationV2
                        className="pagination"
                        pageCount={totalPages}
                        page={page}
                        onPageChange={(page) => onChangePaginationAttendeeAttend({ page })}
                    />
                </DivTable>
                <div className="css_magin_top_16"></div>
                <div className="topic_text">หรือกำหนดเอง</div>
            </div>
            <div className="css_magin_top_16"></div>
            <div className="border_header"></div>
            <div className="css_magin_top_16"></div>
            <div className="dialog-button">
                <ButtonOutline onClick={onClose} >ยกเลิก</ButtonOutline>
                <Button onClick={ProcessApi}>ยืนยัน</Button>
            </div>
        </Div>
    )
}

export default ModalKnowledge