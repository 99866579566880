import React, { useState,useEffect } from 'react'

import styled from 'styled-components'
import { Box } from '@mui/material'
import HeaderPage from 'components/containers/HeaderPage'
import TabGroupMenu from 'components/containers/TabGroupMenu'
import OrgActivityByCoE from 'components/feature/CpdManage/OrgActivityByCoE'
import OrgActivityNotCOE from 'components/feature/CpdManage/OrgActivityNotCOE'
import ReviewOrgActivity from 'components/feature/CpdManage/ReviewOrgActivity'

import {  GetPermissionTabList } from 'utils/common'

const Div = styled.div``

function OrgActivity() {

  const [tabMenu, setTabMenu] = useState('CpdManageOrgActivity_Other')
  const [tabMenuOptions, settabMenuOptions] = useState([])

  return (
    <Div>
      <Box sx={{ mb: 2 }}>
        <HeaderPage heading="กิจกรรมองค์กร" subHeading="สำหรับเจ้าหน้าที่ตรวจสอบกิจกรรมองค์กร" />
      </Box>
      {
        /*
              <Box sx={{ mb: 2 }}>
        <TabGroupMenu tabMenuOptions={tabMenuOptions} tabMenu={tabMenu} setTabMenu={setTabMenu} />
      </Box>
        */
      }

      {tabMenu === 'CpdManageOrgActivity_COE' && <OrgActivityByCoE />}
      {tabMenu === 'CpdManageOrgActivity_Other' && <OrgActivityNotCOE />}
    </Div>
  )
}

export default OrgActivity
