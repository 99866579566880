import dayjs from 'dayjs'
import * as buddhistEra from 'dayjs/plugin/buddhistEra'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/th'
import Papa from 'papaparse'
import {  Tooltip } from '@mui/material'

const ADMIN = 'admin'
dayjs.extend(buddhistEra)
dayjs.extend(localizedFormat)
//const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
const lang = 'th';

export const getAdminToken = () => {
  //const { token } = JSON.parse(localStorage.getItem('accessToken')) || {}
  return localStorage.getItem('accessToken') || null
}

export const getAdminUser = () => {
  //const admin = JSON.parse(localStorage.getItem('user_info')) || null
  return JSON.parse(localStorage.getItem('user_info')) || null
}

export const getMenulist= () => {
  //const admin = JSON.parse(localStorage.getItem('user_info')) || null
  return JSON.parse(localStorage.getItem('menu_list')) || null
}

export const setAdminData = (data) => {
  localStorage.setItem(ADMIN, JSON.stringify(data))
}

export const removeAdminData = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('user_info')
  localStorage.removeItem('UserToken')
  localStorage.removeItem('loglogin_id')
}

export const getFirstSplit = (word, split = '.') => {
  return word.split(split)[0]
}

export const displayDate = (inputDate) => {
  if (inputDate) {
    const date = dayjs(inputDate).locale(lang)
    const formattedDate = lang == 'th' ? date.format('D MMM BBBB h:mm A') : date.format('D MMM YYYY h:mm A')
    return formattedDate
  }
  return null
}

//--- ST Format ตอนแสดงผล วันที่ และเวลาของระบบ จะใช่ format เดี่ยวกันตาม By James
/*
วันที่จะแสดงเป็น 1 Jun 2023 เวลาจะเป็น 24 ชั่วโมง
*/
export const DateDisplayDDMMBBB = (inputDate) => {
  if (inputDate) {
    const date = dayjs(inputDate).locale(lang)
    const formattedDate = lang == 'th' ? date.format('DD/MM/BBBB') : date.format('DD/MM/YYYY')
    return formattedDate
  }
  return null
}

export const DateDisplay = (inputDate) => {
  if (inputDate) {
    const date = dayjs(inputDate).locale(lang)
    const formattedDate = lang == 'th' ? date.format('D MMM BBBB') : date.format('D MMM YYYY')
    return formattedDate
  }
  return null
}

export const DateTimeHHmmssDisplay = (inputDate) => {
  if (inputDate) {
    const date = dayjs(inputDate).locale(lang)
    const formattedDate = lang == 'th' ? date.format('D MMM BBBB HH.mm.ss') : date.format('D MMM YYYY HH.mm.ss')
    return formattedDate
  }
  return null
}

export const DateTimeHHmmDisplay = (inputDate) => {
  if (inputDate) {
    const date = dayjs(inputDate).locale(lang)
    const formattedDate = lang == 'th' ? date.format('D MMM BBBB HH.mm') : date.format('D MMM YYYY HH.mm')
    return formattedDate
  }
  return null
}

export const TimeDisplayHHmm = (inputDate) => {
  if (inputDate) {
    const date = dayjs(inputDate).locale(lang)
    const formattedDate = date.format('HH.mm')
    return formattedDate
  }
  return null
}

export const DateDisplayDDMMBBBHHmm= (inputDate) => {
  if (inputDate) {
    const date = dayjs(inputDate).locale(lang)
    const formattedDate = lang == 'th' ? date.format('DD/MM/BBBB HH.mm') : date.format('DD/MM/YYYY HH.mm')
    return formattedDate
  }
  return null
}

export const DateDisplayDDMMBBBHHmmss= (inputDate) => {
  if (inputDate) {
    const date = dayjs(inputDate).locale(lang)
    const formattedDate = lang == 'th' ? date.format('DD/MM/BBBB HH:mm:ss') : date.format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }
  return null
}

export const TimeDisplayHHmmss = (inputDate) => {
  if (inputDate) {
    const date = dayjs(inputDate).locale(lang)
    const formattedDate = date.format('HH.mm.ss')
    return formattedDate
  }
  return null
}

export const TimeDisplay = (timeValue) => {
  if (timeValue) {
    const hhmm = timeValue.substring(0, 5)
    const convertedTime = hhmm.replace(':', '.')

    return convertedTime
  }
  return null
}
export const TimeDisplay2 = (timeValue) => {
  if (timeValue) {
    const hhmm = timeValue.substring(0, 5)

    return hhmm
  }
  return null
}

//--- ED Format ตอนแสดงผล วันที่ และเวลาของระบบ จะใช่ format เดี่ยวกันตาม By James

export const getEmployeeInfo = () => {
  const admin = JSON.parse(localStorage.getItem(ADMIN)) || null
  return admin?.employee_info ?? null
}

export const getCompanyInfo = () => {
  const admin = JSON.parse(localStorage.getItem(ADMIN)) || null
  return admin?.company_info ?? null
}

// read csv file
export const csvToJson = (csv) => {
  const parsed = Papa.parse(csv, {
    header: true,
    skipEmptyLines: true,
  })

  const langFormat = parsed.data.reduce((acc, cur) => {
    Object.keys(cur).forEach((key) => {
      if (key !== 'key') {
        if (!acc[key]) {
          acc[key] = { translation: {} }
        }

        acc[key].translation[cur.key] = cur[key]
      }
    })

    return acc
  }, {})

  return langFormat
}

export const getAllLocalAdmin = () => {
  return JSON.parse(localStorage.getItem(ADMIN)) || {}
}

export const numberWithCommas = (value) => {
  if (integerCheck(value)) {
    let nf = new Intl.NumberFormat('en-US')
    return nf.format(value)
  } else {
    return value
  }

  //return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const integerCheck = (num) => {
  /*
  const isInt = (n) => Number(n) === n && n % 1 === 0
  const isFloat = (n) => Number(n) === n && n % 1 !== 0
  return (isInt(num) || !isFloat(num))   
  */
  return !isNaN(Number(num))
}

export const DisplayTableActivityName = (value) => {
  if(value.length > 50){
    return value.substring(0,50) + '...'
  }else{
    return value
  }
}

export const DisplayTableActivityDescription = (value) => {
  if(value.length > 50){
    return (
      <Tooltip title={value} placement="top">
        {value.substring(0,50) + '...'}
      </Tooltip>     
    )
    //return value.substring(0,50) + '...'
  }else{
    return (
      <Tooltip title={value} placement="top">
        {value}
      </Tooltip>     
    )
    //return value
  }
}

export const GetPermissionTabList = (key_menu) => {
  let tab_list = []
  if (localStorage.getItem('menu_list')) {
    const menu_list = JSON.parse(localStorage.getItem('menu_list'))
    for (const menu_lv1 of menu_list) {
      if (menu_lv1.key == key_menu) {
        tab_list = menu_lv1.tab_list ? menu_lv1.tab_list : []
        return tab_list
      }
      if (menu_lv1.sub_menu_list) {
        //--- MenuLV2
        for (const menu_lv2 of menu_lv1.sub_menu_list) {
          if (menu_lv2.key == key_menu) {
            tab_list = menu_lv2.tab_list ? menu_lv2.tab_list : []
            return tab_list
          }
        }
        if(tab_list.length == 0){
          return tab_list
        }
      }
    }
  } else {
    return tab_list
  }
}


export const GetPermissionOther = (key_menu, key_permission_other) => {
  const fix_open_permission = {
    canRead: true,
    canUpdate: true,
  }
  return fix_open_permission
}

