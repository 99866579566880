import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import XLSX from "xlsx";

import {  getAdminUser,DateDisplayDDMMBBB,GetPermissionOther } from '../../utils/common'
import { ApiGetOrgType,ApiGetStatus } from '../../redux/actions/master'
import { ApiGetReportCommittee,Apigenerate_report_pdf_org } from '../../redux/actions/organizations'


import TableV2 from '../../components/common/TableV2'
import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import Field from '../../components/form/Field'
import SearchText from '../../components/form/SearchText'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import Modal from '../../components/common/Modal'

import iconPlus from '../../assets/images/icon-plus.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import flagIcon from '../../assets/images/flag-icon.png'
import { IoMdClose } from 'react-icons/io'

const Div = styled.div`
  .box_header{
    display: flex;
    justify-content: space-between;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .css_button_add{
    display: inline-block;
    align-self: flex-end;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_16 {
    margin-top: 16px;
  }
  .status-badge {
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    &.expire {
      color:#B42318;
      background: #FEF3F2;
      border: 1px #FECDCA solid;
    }
    &.active {
      color:#067647;
      background: #ECFDF3;
      border: 1px #ABEFC6 solid;
    }

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #475467;
      

      &.expire {
        color:#B42318;
      }
      &.active {
        color:#067647;
      }

    }
  }

  .table-wrapper {
    /*margin-top: 24px;*/
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header-commitee {
      display: flex;
      justify-content: space-between;
      padding: 0px 14px;
      margin-top: 16px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--BADGE-Table-Font);
        background: var(--BADGE-Table-Bg);
        border-radius: 16px;
        border: 1px var(--BADGE-Table-Border) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .css_magin_top_10 {
    margin-top: 10px;
  }
  .css_button_filter{
    display: inline-block;
    align-self: flex-end;
}
.group_button {
    display: flex;
    align-items: center;
    gap: 10px;
}
.table-sub{
    padding: 0px 14px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
`


const DivFilter = styled.div`
min-height: 68px;
background-color: var(--Gray-50);
border-radius: 8px;
margin-bottom: 21px;
padding: 10px;
.content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .search-wrapper {
        margin-right: 12px;
    }

    .filter-list {
        flex-basis: 100%;
        margin-top: 24px;
    }

    .group_filter {
        display: flex;
        align-items: center;
    }
}
.css_filter_name {
    min-width: 380px;
    margin-right: 10px;
}
.mr-1-rem {
    margin-right: 1rem;
}
.css_button_filter{
    display: inline-block;
    align-self: flex-end;
}
.dd_filter{
    min-width: 380px;
}
.dd_filterStatusExam{
  min-width: 115px;
}


`

const ModalRegister = styled.div`
  padding: 24px;
  min-width: 400px;
  .icon {
    cursor: pointer;
  }
  .top-table{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    /*margin-bottom: 24px;*/
  }
  .dialog-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .content {
      flex: 1;
      min-width: 270px;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }

    .description {
      font-size: 14px;
      margin-top: 4px;
      color: var(--Gray-600);
    }
  }
  .mg-top-space{
    margin-top: 5px;
  }
  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }
`

const TabCommitee = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()

  const permission_CpdManageOrgApproveList_Comittee_Report = GetPermissionOther('CpdManageOrgApproveList_Comittee','CpdManageOrgApproveList_Comittee_Report');

  const [DataInfo, setDataInfo] = useState(null)
  const [category_typeOption, setcategory_typeOption] = useState([
    {
        text : 'ยื่นสมัครเป็นองค์กร',
        value : '0'
    },
    {
        text : 'ต่ออายุองค์กร',
        value : '1'
    },
    {
        text : 'ต่ออายุหลังหมดอายุ',
        value : '2'
    },
    {
        text : 'ต่ออายุองค์กร (อัตโนมัติ)',
        value : '3'
    },
    {
        text : 'ต่ออายุหลังหมดอายุ (อัตโนมัติ)',
        value : '4'
    },
    {
        text : 'ต่ออายุหลังหมดอายุ เกิน3ปี',
        value : '5'
    },
  ])
  const [status_assessment_resultsOption, setstatus_assessment_resultsOption] = useState([
    {
        text : 'ผ่าน',
        value : 'ผ่าน'
    },
    {
        text : 'ไม่ผ่าน',
        value : 'ไม่ผ่าน'
    },
  ]) 
  const [type_dateOption, settype_dateOption] = useState([
    {
        text : 'วันที่ประชุมอนุกรรมการ',
        value : 'date_subcommittee'
    },
    {
        text : 'วันที่ประชุมคณะกรรมการสภา',
        value : 'date_committee_approve'
    },
  ]) 
  
  
  const initialFilter = {
    name : '',
    is_type_extend : '', //--- ประเภทคำขอ
    status_assessment_results : '', //--- ผลการตรวจประเมิน
    type_date : '', //--- เลือกประเภทวันที่
    start_date : null,
    end_date : null
  };
  const [Filter, setFilter] = useState(initialFilter);

  const limit_data = 10;
  const [total, settotal] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumnData = [
    {
      Header: 'ลำดับ',
      accessor: 'no',
      disableSortBy: false,
    },
    {
        Header: 'เลขที่รับเรื่อง',
        accessor: 'request_no',
        disableSortBy: false,
    },
    {
        Header: 'ชื่อองค์กร',
        accessor: 'name_th',
        disableSortBy: false,
    },
    {
        Header: 'ประเภทคำขอ',
        accessor: 'is_type_extend',
        disableSortBy: false,
    },
    {
        Header: 'ประเภท',
        accessor: 'org_type_name',
        disableSortBy: false,
    },
    {
        Header: 'ผลการตรวจประเมิน',
        accessor: 'status_assessment_results',
        disableSortBy: false,
    },
    {
        Header: 'วันที่ประชุมอนุกรรมการ',
        accessor: 'committee_date',
        disableSortBy: false,
    },
    {
        Header: 'วันที่ประชุมคณะกรรมการสภา',
        accessor: 'committee_coe_date',
        disableSortBy: false,
    },
  ]

  const fetchData = useCallback(
    async (filter) => {

      dispatch(ApiGetReportCommittee(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if (data_info.total > 0) {
            const totalPage = Math.ceil(data_info.total / limit_data)
            settotal(data_info.total)
            setTotalPages(totalPage)
            const ThisSkip = data_info.skip == 0 ? 1 : (data_info.skip / limit_data) + 1;

            const newTableCellData = data_info.data.map((v, index) => ({
              no: limit_data * ThisSkip - (9 - index),
              request_no: v.request_no ? v.request_no : '-',
              name_th: v.name_th ? v.name_th : '-',
              is_type_extend: v.is_type_extend_display ? v.is_type_extend_display : '-',
              org_type_name: v.org_type_name ? v.org_type_name : '-',
              status_assessment_results: v.record_consideration_name ? v.record_consideration_name : '-',
              committee_date: v.date_subcommittee ? DateDisplayDDMMBBB(v.date_subcommittee) : '-',
              committee_coe_date: v.date_committee_approve ? DateDisplayDDMMBBB(v.date_committee_approve) : '-',
            }))
            setTableCellData(newTableCellData)
          }else{
            settotal(0);
            setPage(0);
            setTotalPages(0);
            setTableCellData([]);
          }
         
        }
      })

    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter) //-- ปิด รอ API
  }, [fetchData, limit_data])


  const onChangePaginationAttendeeAttend = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data, 
      limit: limit_data,
    }
    fetchData(filter)
    setPage(value.page)
  }

  const OpenCreateModal = () => {
    alert('สร้างบทความใหม่')
  }

  const onClearfilterName = () => {
    setFilter({ ...Filter, name: '' })
  }

  const onSearch = () => {
    const filter = {
      skip: 0,
      limit: limit_data,
      name : Filter.name,
      is_type_extend : Filter.is_type_extend,
      status_assessment_results : Filter.status_assessment_results,
      type_date : Filter.type_date,
      start_date : Filter.start_date,
      end_date : Filter.end_date,
    }
    fetchData(filter)
    setPage(1)
  }
  const onClearSearch = () => {
    setFilter(initialFilter)
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter)
    setPage(1)
  }

  const [isOpenModalPDF, setisOpenModalPDF] = useState(false)
  const [date_subcommittee, setdate_subcommittee] = useState(null);

  const onApiGeneratePDF = () => {
    setisOpenModalPDF(false);
    const sent_data_api = {
      date_subcommittee: date_subcommittee
    }
    dispatch(Apigenerate_report_pdf_org(sent_data_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
          window.open(data_info.fullpath, "_blank");
        }        
      }
    })
  }

  const loadExcel = () => {
    //alert('loadExcel')
    const filter = {
      skip: 0,
      limit: 10000,
    }
    dispatch(ApiGetReportCommittee(filter)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if (data_info.total > 0) {
          const resultToxlsx = data_info.data.map((v,index) => {
              return {
                  "ลำดับ" : index + 1,
                  "เลขที่รับเรื่อง" : v.request_no ? v.request_no : '-',
                  "ชื่อองค์กร" : v.name_th ? v.name_th : '-',
                  "ประเภทคำขอ" : v.is_type_extend_display ? v.is_type_extend_display : '-',
                  "ประเภท" : v.org_type_name ? v.org_type_name : '-',
                  "ผลการตรวจประเมิน" : v.record_consideration_name ? v.record_consideration_name : '-',
                  "วันที่ประชุมอนุกรรมการ" : v.date_subcommittee ? DateDisplayDDMMBBB(v.date_subcommittee) : '-',
                  "วันที่ประชุมคณะกรรมการสภา" : v.date_committee_approve ? DateDisplayDDMMBBB(v.date_committee_approve) : '-',                 
              };
          });
          const dataWS = XLSX.utils.json_to_sheet(resultToxlsx);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, dataWS);
          XLSX.writeFile(wb, "เตรียมรายงานเข้าคณะกรรมการ.xlsx");
        }
      }
    })
  }

  return (
    <Div>
        <div className="css_magin_top_16"></div>
        <DivFilter>
          <div className="content-fillter">
            <div className="group_filter">
              <div className="css_filter_name">
                <Field className="field" label="ค้นหาด้วยชื่อหรือเลขประจำตัวผู้เสียภาษี">
                  <SearchText
                    placeholder="Search ชื่อ หรือ เลขประจำตัวผู้เสียภาษี"
                    value={Filter.name}
                    onChange={(e) => setFilter({ ...Filter, name: e.target.value })}
                    onClear={onClearfilterName}
                  />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="ประเภทคำขอ">
                  <Dropdown
                      id={`dd_filter`}
                      className="dd_filter"
                      value={Filter.is_type_extend}
                      optionList={category_typeOption}
                      onChange={(v) => setFilter({ ...Filter, is_type_extend: v })}
                      placeHolder={'เลือก'}
                  />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="ผลการตรวจประเมิน">
                  <Dropdown
                      id={`dd_filter`}
                      className="dd_filter"
                      value={Filter.status_assessment_results}
                      optionList={status_assessment_resultsOption}
                      onChange={(v) => setFilter({ ...Filter, status_assessment_results: v })}
                      placeHolder={'เลือก'}
                  />
                </Field>
              </div>
            </div>
          </div>
          <div className="css_magin_top_16"></div>
          <div className="content-fillter">
            <div className="group_filter">
              <div className="mr-1-rem">
                <Field className="field" label="เลือกประเภทวันที่">
                  <Dropdown
                      id={`dd_filter`}
                      className="dd_filter"
                      value={Filter.type_date}
                      optionList={type_dateOption}
                      onChange={(v) => setFilter({ ...Filter, type_date: v })}
                      placeHolder={'เลือก'}
                  />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="ตั้งแต่วันที่">
                    <Date
                        className={'input-calendar'}
                        value={Filter.start_date}
                        onChange={(v) => setFilter({ ...Filter, start_date: v })}
                    />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="ตั้งแต่วันที่">
                    <Date
                        className={'input-calendar'}
                        value={Filter.end_date}
                        onChange={(v) => setFilter({ ...Filter, end_date: v })}
                    />
                </Field>
              </div>
              <div className="css_button_filter mr-1-rem">
                  <Button onClick={onSearch} >
                    ค้นหา
                  </Button>
              </div>
              <div className="css_button_filter mr-1-rem">
                <ButtonSecondary onClick={onClearSearch} >
                  ล้างการค้นหา
                </ButtonSecondary>
              </div>
            </div>
          </div>
        </DivFilter>
        <div className="css_magin_top_16"></div>
        <div className="table-wrapper">
          <div className="table-header-commitee">
            <div className="group">
              <div className="table-title">จำนวนรายการรออนุมัติผล</div>
              <div className="table-total">
                {total} รายการ
              </div>
            </div>
            <div className="css_button_filter">
                <div className="group_button">
                    <ButtonOutline onClick={() => loadExcel()}>Download</ButtonOutline>
                    {permission_CpdManageOrgApproveList_Comittee_Report?.canRead === true && (
                      <ButtonOutline  onClick={() => setisOpenModalPDF(true)}>
                      สร้างรายงาน
                      </ButtonOutline>
                    )}
                </div>
            </div>
          </div>
          <div className="table-sub">แสดงเฉพาะรายการที่รอเข้าที่ประชุม กรณีต้องการแก้ไขวันที่ ให้แก้ที่หน้าบันทึกผลพิจารณา</div>
          <div className="css_magin_top_10">
            <TableV2
              columns={TableColumnData}
              data={TableCellData}
              onStateChange={onChangePaginationAttendeeAttend}
              pageCount={totalPages}
              page={page}
              loading={false}
            />
          </div>
        </div>
              {/* Modal */}
      <Modal open={isOpenModalPDF} disableBackdropClick>
        <ModalRegister>
          <div className="top-table">
            <div className="icon-content">
              <img src={flagIcon} />
            </div>
            <div className="close-modal-content">
              <IoMdClose size={20} className="icon" onClick={() => setisOpenModalPDF(false)} />
            </div>
          </div>
          <div className="dialog-content">
            <div className="content">
              <div className="title">สร้างรายงานเข้าที่ประชุมคณะอนุกรรมการ</div>
              <div className="description">กรุณาเลือกวันที่ประชุมคณะอนุกรรมการ</div>
              <div className="mg-top-space">
                <Field className="field" label="ระบุวันที่ประชุม">
                    <Date
                        className={'input-calendar'}
                        value={date_subcommittee}
                        onChange={(v) => setdate_subcommittee(v)}
                    />
                </Field>
              </div>
            </div>
          </div>
          <div className="dialog-button">
            <ButtonSecondary onClick={() => setisOpenModalPDF(false)}>ยกเลิก</ButtonSecondary>
            <Button onClick={onApiGeneratePDF}>ยืนยัน</Button>
          </div>
        </ModalRegister>
      </Modal>
    </Div>
  )
}

export default TabCommitee
