import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import Field from '../components/form/Field'
import TextField from '../components/form/TextField'
import Button from '../components/form/button/Button'
import { validateEmail } from '../utils/validation'
import { loginUser } from '../redux/actions/admin'
import DialogFail from '../components/dialog/DialogFail'

import logoIcon from '../assets/images/logo-icon.png'
import { VERSION } from '../configs/app'
import failIcon from '../assets/images/fail-icon.png'

import { selectMaster } from '../redux/selectors'

const Div = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  .img-container {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 48px;
      height: 48px;
    }
  }

  .title {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    font-style: normal;
    color: var(--Gray-900);
  }

  .desc {
    text-align: center;
    margin-top: 12px;
  }

  .login-form {
    margin-top: 32px;

    .field {
      margin-top: 20px;
    }

    .link-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;

      color: var(--Primary-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    button {
      display: block;
      width: 100%;
      margin-top: 24px;
    }
  }

  .hightlight {
    color: var(--Primary-700);
    font-weight: 800;
  }

  .copyright {
    color: var(--Gray-500);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 180px;
  }
`

const Login = () => {
  const lang = localStorage.getItem('lang')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isRedirect, companyInfo } = useSelector(selectMaster)
  const [UserName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [failModal, setFailModal] = useState(false)


  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(loginUser(UserName, password)).then(({ error,type,data_info }) => {
        if (!error) {
            if(type.endsWith('_SUCCESS')){
                if(data_info.accessToken){
                    localStorage.setItem('accessToken', data_info.accessToken);
                    localStorage.setItem('user_info', JSON.stringify(data_info.users_admin ));
                    window.location.href = '/'
                }else{
                    setFailMessageModal({
                        headline: 'Incorrect Username/Password',
                        message: 'กรุณาลองใหม่อีกครั้ง',
                    })
                    setFailModal(true)
                }
            }else{
                setFailMessageModal({
                    headline: 'Incorrect Username/Password',
                    message: 'กรุณาลองใหม่อีกครั้ง',
                })
                setFailModal(true)
            }
        }else{
            setFailMessageModal({
                headline: 'Incorrect Username/Password',
                message: 'กรุณาลองใหม่อีกครั้ง',
            })
            setFailModal(true)
        }
    })
  }

  const gotoForgetPassword = () => {
    navigate('/forget-password')
  }

  return (
    <Div>
      <form>
        <div className="img-container">
          <img src={logoIcon} />
        </div>
        <div className="title">Log in to your account</div>
        <div className="desc">
          Welcome back to{' '}
          <span className="hightlight">
            {'CPD System!'}
          </span>{' '}
          <br /> Please enter your details.
        </div>
        <div className="login-form">
          <Field className="field" label="Username">
            <TextField placeholder="Enter your Username" value={UserName} onChange={(e) => setUserName(e.target.value)} />
          </Field>
          <Field className="field" label="Password">
            <TextField type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </Field>
          {
            /*
            <div className="link-wrapper" onClick={gotoForgetPassword}>
                Forgot password
            </div>
            */
          }

          <Button type="submit" onClick={handleLogin} disabled={!UserName || !password}>
            Sign in
          </Button>
        </div>
        <div className="copyright">© 2023 CPD ERP. All rights reserved.</div>
        <div className="copyright">version {VERSION}</div>
      </form>
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={'ตกลง'}
      />
    </Div>
  )
}

export default Login
