import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ApiGetOrgProfileCMS } from '../../redux/actions/organizations'

import TabOrgProfile from '../../pages/organization/OrgProfile'
import TabOrgUserAccount from '../../pages/organization/OrgUserAccount'

import { RiHome6Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'

const WidthSidePanel = window.innerWidth - 92;

const DivMain = styled.div`
color: var(--Gray-600);
font-size: 14px;
font-style: normal;
font-weight: 500;
.header {
    display: flex;
    align-items: center;

    .highlight {
        color: var(--Primary-700);
        font-weight: 600;
    }
}
.mb-2-rem {
    margin-bottom: 2rem;
}
.mr-8-px {
    margin-right: 8px;
}
.icon {
    cursor: pointer;
}
.content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
}
.content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
}
.border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
}
.wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;
    /*border-bottom: 1px solid var(--Tab-Border-Default);*/

    .btn-menu-style {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background-color: var(--Tab-Bg-Default);
        /*border-bottom: 0px solid var(--Tab-Border-Default);*/
        border-bottom: 2px solid var(--Tab-Border-Default);
        color: var(--Tab-Font-Default);  
        font-size: 14px;
        /*font-family: 'Inter','Noto Sans Thai';*/
        font-family: inherit;
    }

    .menu-active {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 2px solid var(--Tab-Border-Current) !important;
        color: var(--Tab-Font-Current); 
        background-color: var(--Tab-Bg-Current);
        /*font-family: 'Inter','Noto Sans Thai';*/
        font-family: inherit;
    }

}
.content_header {
  position: fixed;
  background: #FFFFFF;
  top: 24px;
  width : ${WidthSidePanel}px;
  z-index: 1;
}
.content_middle {
  padding-top: 174px;
}
`

function OrgProfile() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  //const [WidthSidePanel, setWidthSidePanel] = useState(window.innerWidth - 92)

  const [isOpenData, setisOpenData] = useState(false)
  const [DataInfo, setDataInfo] = useState(null)
  const [OldDataInfo, setOldDataInfo] = useState(null)

  const [tabactive, setTabactive] = useState('Profile')
  const [menuTabList, setMenuTabList] = useState([
    {
      name: 'ข้อมูล',
      value: 'Profile',
      active_status: true,
    },
    {
      name: 'บัญชีผู้ใช้',
      value: 'Account',
      active_status: false,
    },
  ])
  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setTabactive(tmpList[i].value)
      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }

  const backToMeeting = () => {
    navigate('/CpdManage/OrgList')
  }

  useEffect(() => {
    const sent_data_api = {
        organizations_id: id
    }
    dispatch(ApiGetOrgProfileCMS(sent_data_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        console.log(data_info.data_info)
        if(data_info.data_info){

          setDataInfo(data_info.data_info);
          setOldDataInfo(data_info.data_info);
          setisOpenData(true)
        }
        
      }
    })
  }, [dispatch])


  return (
    <div>
        {isOpenData == true && (
            <DivMain >
                <div className="content_header">
                  <div className="header mb-2-rem">
                      <RiHome6Line size={20} className="mr-8-px" />
                      <MdOutlineKeyboardArrowRight className="mr-8-px" />
                      <span className="icon mr-8-px" onClick={backToMeeting}>
                      รายชื่อองค์กร
                      </span>
                      <MdOutlineKeyboardArrowRight className="mr-8-px" />
                      <span className="highlight">{DataInfo.name_th}</span>
                  </div>
                  <div className="content-title">
                      {DataInfo.name_th}
                  </div>
                  <div className="content-title-text">
                      สำหรับเจ้าหน้าที่ตรวจสอบข้อมูลและจัดการข้อมูลของ
                  </div>
                  <div className="border_header"></div>
                </div>
                <div className="content_middle">
                  <div className="wrp-tab-menu">
                      {menuTabList?.map((item, index) => (
                          <button
                          id={'this_name-' + item.value}
                          key={'btn-' + index}
                          className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                          onClick={() => handleClickTaskMenuTab(item)}
                          >
                          {item.name}
                          </button>
                      ))}
                  </div>
                  {tabactive == 'Profile' && (
                      <div>
                          <TabOrgProfile this_id={id} />
                      </div>
                  )}
                  {tabactive == 'Account' && (
                      <div>
                          <TabOrgUserAccount this_id={id} />
                      </div>
                  )}
                </div>
            </DivMain>
        )}
    </div>
  )
}

export default OrgProfile
