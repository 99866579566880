import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { getTitle } from '../../redux/actions/master'
import { ApiNewUserAccount } from '../../redux/actions/organizations'

import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import InputTextArea from '../../components/widget/element/InputTextArea'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import question from '../../assets/images/question.png'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import PasswordField from '../../components/form/PasswordInput'

import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import { useTranslation } from 'react-i18next'

const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  }
  .content_header {
    padding: 14px 14px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 90px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .wrp-tool-left {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_upload {
    margin-top: 10px;
  }
  .topic_fill{
    font-size: 14px;
    color: #344054;
    margin: 5px 0px;
  }
  .TextField_employee {
    width: 100%;
  }
  .content{
    margin:10px 0px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .prefix_icon{
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
`

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
}

const NewUserAccount = ({ onClose, onSubmit,this_id,...props  }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  
  const [EditData, setEditData] = useState({
    organizations_id : this_id,
    title_id : '',
    first_name : null,
    last_name : null,
    user_name : null,
    email : null,
    phone_no : null,
  })

  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const ConfirmDialog = () => {
    setConfirmMessageModal({
        headline : 'ยืนยันการทำรายการเพิ่มบัญชีผู้ใช้',
        message : '',
        full_name : 'ชื่อ นามสกุล : ' + EditData.first_name + ' ' + EditData.last_name,
        Email : 'Email : ' + EditData.email,
        Phonse : 'เบอร์โทร : ' + EditData.phone_no,
        user_name : 'เลขบัตรประชาชน : ' + EditData.user_name,
    })
    setModalConfirmDialog(true)
  }
  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const [title_option, settitle_option] = useState([])
  const status_option = [
    { text: 'request', value: 3 },
    { text: 'active', value: 1 },
    { text: 'deny', value: 2 },
  ]
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  useEffect(() => {
    dispatch(getTitle()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newtitle_option = data_info.data.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            //console.log(newtitle_option)
            settitle_option([...newtitle_option])
        }
    })
  }, [dispatch])
  const ProcessUpdate = () => {
    setModalConfirmDialog(false);

    
    const sent_data_api = EditData;
    dispatch(ApiNewUserAccount(sent_data_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
            setTitlesuccess('ทำรายการสำเร็จ');
            setSubTitlesuccess('');
            setDescriptionsuccess(''); 
            setsuccessModal(true)
        }else{
            setTitleFail(data_info.message);
            setnameItemFail('ไม่สามารถสร้างซ้ำได้ กรุณาลองใหม่อีกครั้ง');
            setDescriptionFail(''); 
            setFailModal(true)
        }
      }else{
        setTitleFail('internal server error');
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }     
    })
    
    //onSubmit()
  }
  const onTitle = (v) => {
    if(v){
        setEditData({ ...EditData, title_id: v })
    }
  }
  const onStatus = (v) => {
    if(v){
        setEditData({ ...EditData, status_id: v })
    }
  }


  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">เพิ่มบัญชีผู้ใช้</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">เพิ่มผู้ใช้อื่นภายใต้องค์กร</div>
        <hr />
      </div>
      
      <div className="dialog-content">
        <div className="content">
            <div className="topic_fill">คำนำหน้า*</div>
            <div>
                <Dropdown
                    id={`dd_title_option`}
                    value={EditData.title_id}
                    optionList={title_option}
                    onChange={onTitle}
                    placeHolder={'เลือกคำนำหน้า'}
                />
            </div>
        </div>

        <div className="content">
            <div className="topic_fill">ชื่อ*</div>
            <div >
                <TextField
                    name="first_name"
                    className="TextField_employee"
                    placeholder={'ระบุชื่อ'}
                    value={EditData.first_name}
                    onChange={(e) => setEditData({ ...EditData, first_name: e.target.value })}
                />
            </div>
        </div>
        <div className="content">
            <div className="topic_fill">นามสกุล*</div>
            <div >
                <TextField
                    name="last_name"
                    className="TextField_employee"
                    placeholder={'ระบุนามสกุล'}
                    value={EditData.last_name}
                    onChange={(e) => setEditData({ ...EditData, last_name: e.target.value })}
                />
            </div>
        </div>
        <div className="content">
            <div className="topic_fill">เลขบัตรประจำตัวประชาชน*</div>
            <div >
                {
                  /*
                  <TextField
                      name="user_name"
                      className="TextField_employee"
                      placeholder={'ระบุเลขบัตรประจำตัวประชาชน'}
                      value={EditData.user_name}
                      onChange={(e) => setEditData({ ...EditData, user_name: e.target.value })}
                  />
                  */
                }

                <PasswordField 
                  value={EditData.user_name} 
                  onChange={(e) => setEditData({ ...EditData, user_name: e.target.value })}
                />
                
            </div>
        </div>
        <div className="content">
            <div className="topic_fill">Email (เพื่อติดต่อประสานงานและรับรหัส OTP)*</div>
            <div >
                <TextField
                    name="email"
                    className="TextField_employee"
                    placeholder={'ระบุEmail'}
                    value={EditData.email}
                    onChange={(e) => setEditData({ ...EditData, email: e.target.value })}
                />
            </div>
        </div>
        <div className="content">
            <div className="topic_fill">เบอร์โทรศัพท์ (เพื่อติดต่อประสานงานและรับรหัส OTP)*</div>
            <div >
                <TextField
                    name="phone_no"
                    className="TextField_employee"
                    placeholder={'ระบุเบอร์โทรศัพท์'}
                    value={EditData.phone_no}
                    onChange={(e) => setEditData({ ...EditData, phone_no: e.target.value })}
                />
            </div>
        </div>
        
      </div>
      
      <div className="dialog-footer">
        <hr />
        <ButtonOutline className="button_margin" onClick={onClose}>
          {'ยกเลิก'}
        </ButtonOutline>
        <Button className="button_margin" onClick={ConfirmDialog}>
          {'เพิ่มผู้ใช้'}
        </Button>
      </div>

        <DialogConfirmV2
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onNo={() => setModalConfirmDialog(false)}
          onSubmit={ProcessUpdate}
          icon={successIcon}
          title={ConfirmMessageModal.headline}
          nameItem={ConfirmMessageModal.message}
          html={
            <div>
              <div>{ConfirmMessageModal.full_name}</div>
              <div>{ConfirmMessageModal.Email}</div>
              <div>{ConfirmMessageModal.Phonse}</div>
            </div>
          }
          textYes={'ยืนยัน'}
          textNo={'ยกเลิก'}
        />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={'ตกลง'}
      />

        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={'ตกลง'}
        />
    </Div>
  )
}

export default NewUserAccount
