import { Navigate, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom'

import { getAdminToken } from './utils/common'

import { headerCpdManage } from './configs/headerMenu.js'

import { subMenuHome, subMenuCpdManage } from './configs/subMenu'

import AdminLayout from './pages/AdminLayout'
import LoginToken from './pages/LoginToken'
import Login from './pages/Login'

//---- HOME
import Home from './pages/Home'

//---- Cpd Program
import OrgList from './pages/CpdManage/OrgList'
import OrgProfile from './pages/CpdManage/OrgProfile'
import UserList from './pages/CpdManage/UserList'
import OrgCondition from './pages/CpdManage/OrgCondition'
import OrgActivity from './pages/CpdManage/OrgActivity'
import CreateOrgActivity from 'pages/CpdManage/CreateOrgActivity'
import EditOrgActivity from 'pages/CpdManage/EditOrgActivity'
import EventCompareUser from 'pages/CpdManage/EventCompareUser'
import OrgApproveList from 'pages/CpdManage/OrgApproveList'
import OrgApplicationsProfile from 'pages/CpdManage/OrgApplicationsProfile'
import OrgApplicationsExtendProfile from 'pages/CpdManage/OrgApplicationsExtendProfile'


import Elearning from './pages/elearning/Elearning'
import ElearningInfo from './pages/elearning/ElearningInfo'

import ActivityMember from './pages/activity_member/ActivityMember'
import ActivityMemberInfo from './pages/activity_member/ActivityMemberInfo'

import Appeal from './pages/Appeal/Appeal'

import PageNotFound from './pages/PageNotFound'
import Maintenance from './pages/Maintenance'

const checkAuthenticated = () => {
  if (!getAdminToken()) {
    return redirect('/Login')
  }
  return null
}

const router = createBrowserRouter([
  {
    path: '/',
    loader: checkAuthenticated,
    children: [
      {
        path: '/',
        element: <AdminLayout subMenu={subMenuHome} />,
        children: [
          {
            path: '/',
            element: <Home />,
          },
        ],
      },
      {
        path: '/CpdManage',
        element: <AdminLayout headerMenu={headerCpdManage} subMenu={subMenuCpdManage} />,
        children: [
          {
            index: true,
            element: <Navigate to="/OrgList" />,
          },
          {
            path: 'OrgList',
            children: [
              {
                index: true,
                element: <OrgList />,
              },
            ],
          },
          {
            path: 'OrgProfile/:id',
            children: [
              {
                index: true,
                element: <OrgProfile />,
              },
            ],
          },
          {
            path: 'UserList',
            children: [
              {
                index: true,
                element: <UserList />,
              },
            ],
          },
          {
            path: 'OrgCondition',
            children: [
              {
                index: true,
                element: <OrgCondition />,
              },
            ],
          },
          {
            path: 'OrgActivity',
            children: [
              {
                index: true,
                element: <OrgActivity />,
              },
              {
                path: 'create',
                element: <CreateOrgActivity />,
              },
              {
                path: ':id',
                element: <EditOrgActivity />,
              },
            ],
          },
          {
            path: 'EventCompareUser/:id',
            children: [
              {
                index: true,
                element: <EventCompareUser />,
              },
            ],
          },
          {
            path: 'OrgApproveList',
            children: [
              {
                index: true,
                element: <OrgApproveList />,
              },
            ],
          },
          {
            path: 'OrgApplicationsProfile/:ApplicationsId',
            children: [
              {
                index: true,
                element: <OrgApplicationsProfile />,
              },
            ],
          },
          {
            path: 'OrgApplicationsExtendProfile/:ApplicationsId',
            children: [
              {
                index: true,
                element: <OrgApplicationsExtendProfile />,
              },
            ],
          },
          
          
        ],
      },
      {
        path: '/Activity',
        element: <AdminLayout headerMenu={headerCpdManage} subMenu={subMenuCpdManage} />,
        children: [
          {
            index: true,
            element: <Navigate to="/Activity" />,
          },
          {
            path: 'Elearning',
            children: [
              {
                index: true,
                element: <Elearning />,
              },
            ],
          },
          {
            path: 'ElearningInfo/:id',
            children: [
              {
                index: true,
                element: <ElearningInfo />,
              },
            ],
          },
          {
            path: 'ActivityMember',
            children: [
              {
                index: true,
                element: <ActivityMember />,
              },
            ],
          },
          {
            path: 'ActivityMemberInfo/:user_no',
            children: [
              {
                index: true,
                element: <ActivityMemberInfo />,
              },
            ],
          },
          
        ],
      },
      {
        path: '/Appeal',
        element: <AdminLayout headerMenu={headerCpdManage} subMenu={subMenuCpdManage} />,
        children: [
          {
            index: true,
            element: <Navigate to="/Appeal" />,
          },
          {
            path: 'Appeal',
            children: [
              {
                index: true,
                element: <Appeal />,
              },
            ],
          },                  
        ],
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/LoginToken/:token',
    element: <LoginToken />,
  },
  {
    path: '/Maintenance',
    element: <Maintenance />,
    //loader: async () => redirect('https://cpdcmsv2.coe.or.th') //---- แบบ URL
  },
  {
    path: '/*',
    element: <PageNotFound />,
  },
])

const Router = () => {
  return <RouterProvider router={router} />
}

export default Router
