import { FiActivity, FiUsers } from 'react-icons/fi'
import { AiOutlineApartment } from 'react-icons/ai'
import { LuUserSquare2 } from 'react-icons/lu'
import { LiaUserEditSolid } from 'react-icons/lia'
import { FaRegStar } from 'react-icons/fa'
import { BsGraphUpArrow, BsArrowUpRightCircle } from 'react-icons/bs'
import { IoBookOutline } from 'react-icons/io5'
import { TbMessageQuestion } from "react-icons/tb";

export const subMenuHome = [
  {
    name: 'Home',
    link_to: '/',
    subject: null,
  },
]

export const subMenuCpdManage = [
  /*
  {
    class_css: <FiActivity />,
    name: 'หลักเกณฑ์และเงื่อนไข',
    link_to: '/CpdManage/OrgCondition',
    subject: null,
  },
  */
  {
    class_css: <FiUsers />,
    name: 'รายชื่อองค์กร',
    link_to: '/CpdManage/OrgList',
    subject: null,
  },
  /*
  {
    class_css: <LuUserSquare2 />,
    name: 'อนุมัติ',
    link_to: '/CpdManage/OrgApproveList',
    subject: null,
  },
  */
  {
    class_css: <LiaUserEditSolid />,
    name: 'บัญชีผู้ใช้ทั้งหมด',
    link_to: '/CpdManage/UserList',
    subject: null,
  },
  {
    class_css: <AiOutlineApartment />,
    name: 'กิจกรรมองค์กร',
    link_to: '/CpdManage/OrgActivity',
    subject: null,
  },
  {
    class_css: <IoBookOutline />,
    name: 'จัดการข้อมูล E-learning',
    link_to: '/Activity/Elearning',
    subject: null,
  },
  {
    class_css: <FaRegStar />,
    name: 'กิจกรรมสมาชิก',
    link_to: '/Activity/ActivityMember',
    subject: null,
  },
  /*
  {
    class_css: <TbMessageQuestion />,
    name: 'คำร้องอุทธรณ์',
    link_to: '/Appeal/Appeal',
    subject: null, 
  },
  */
  {
    class_css: <BsGraphUpArrow />,
    name: 'รายงาน',
    link_to: '/CpdManage/OrgReport',
    subject: null,
  },
]
