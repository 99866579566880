import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {  getAdminUser,DateDisplayDDMMBBB,DateTimeHHmmDisplay,GetPermissionOther,DateDisplayDDMMBBBHHmmss,DisplayTableActivityDescription } from '../../utils/common'
import { ApiGetVWUserPointHistory,ApiUpdateUserActivityPoint } from '../../redux/actions/events'

import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import SearchText from '../../components/form/SearchText'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import Field from '../../components/form/Field'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import Modal from '../../components/common/Modal'
import ConfirmCancelActivityModalContent from '../../components/feature/CpdManage/ConfirmCancelActivityModalContent'

import iconPlus from '../../assets/images/icon-plus.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import warningIcon from '../../assets/images/warning-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

const Div = styled.div`
  .box_header{
    display: flex;
    justify-content: space-between;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .css_button_add{
    display: inline-block;
    align-self: flex-end;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_16 {
    margin-top: 16px;
  }
  .status-badge {
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #344054;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    &.expire {
      color:#B42318;
      background: #FEF3F2;
      border: 1px #FECDCA solid;
    }
    &.active {
      color:#067647;
      background: #ECFDF3;
      border: 1px #ABEFC6 solid;
    }

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #344054;
      

      &.expire {
        color:#B42318;
      }
      &.active {
        color:#067647;
      }

    }
  }

  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--BADGE-Table-Font);
        background: var(--BADGE-Table-Bg);
        border-radius: 16px;
        border: 1px var(--BADGE-Table-Border) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .css_magin_top_10 {
    margin-top: 10px;
  }
  .text-bold {
    font-weight: bold;
  }
  
  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .button_cancle_data {
    color: #A91F23;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
  }
`

const DivFilter = styled.div`
min-height: 68px;
background-color: var(--Gray-50);
border-radius: 8px;
margin-bottom: 21px;
padding: 10px;
.content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .search-wrapper {
        margin-right: 12px;
    }

    .filter-list {
        flex-basis: 100%;
        margin-top: 24px;
    }

    .group_filter {
        display: flex;
        align-items: center;
    }
}
.css_filter_name {
    min-width: 380px;
    margin-right: 10px;
}
.mr-1-rem {
    margin-right: 1rem;
}
.css_button_filter{
    display: inline-block;
    align-self: flex-end;
}
.dd_filter{
    min-width: 380px;
}
.dd_filterStatusExam{
  min-width: 115px;
}

`



const ActivityMember = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const navigate = useNavigate()

  const permission_ActivityActivityMember_View = GetPermissionOther('ActivityActivityMember','ActivityActivityMember_View');
  const permission_ActivityActivityMember_Cancle = GetPermissionOther('ActivityActivityMember','ActivityActivityMember_Cancle');

  const [RefActivityOption, setRefActivityOption] = useState([
    {
        text: 'E-learning',
        value: 'E-learning',
    },
    {
        text: 'กิจกรรมแม่ข่าย',
        value: 'กิจกรรมแม่ข่าย',
    },
    {
        text: 'Self-declaration',
        value: 'Self-declaration',
    },
  ])
  const [Filter, setFilter] = useState({
    name : null,
    ref_activity : '',
    effective_date : null
  })

  const limit_data = 10;
  const [total, settotal] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumnData = [
    {
      Header: 'ลำดับ',
      accessor: 'no',
      disableSortBy: false,
    },
    {
        Header: 'เลขที่สมาชิก',
        accessor: 'user_no',
        disableSortBy: false,
    },
    {
        Header: 'ชื่อ นามสกุล',
        accessor: 'full_name',
        disableSortBy: false,
    },
    {
        Header: 'ชื่อกิจกรรม',
        accessor: 'activity_name',
        disableSortBy: false,
    },
    {
        Header: 'สาขาวิศวกรรม',
        accessor: 'engineering_field_name',
        disableSortBy: false,
    },
    {
      Header: 'บริษัท',
      accessor: 'organizations_name',
      disableSortBy: false,
    },
    {
        Header: 'ประเภทกิจกรรม',
        accessor: 'ref_activity',
        disableSortBy: false,
    },
    {
        Header: 'วันที่ทำรายการ',
        accessor: 'createdAt',
        disableSortBy: false,
    },
    {
        Header: 'คะแนน CPD',
        accessor: 'user_point',
        disableSortBy: false,
    },
    {
        Header: 'วันที่เข้าร่วมกิจกรรม',
        accessor: 'effective_date',
        disableSortBy: false,
    },
    {
        Header: 'วันที่คะแนนหมดอายุ',
        accessor: 'expire_date',
        disableSortBy: false,
    },
    {
        Header: 'สถานะ',
        accessor: 'status_name',
        disableSortBy: false,
    },
    {
        accessor: 'cancle_data',
        disableSortBy: false,
    },
  ]

  const fetchData = useCallback(
    async (filter) => {

      dispatch(ApiGetVWUserPointHistory(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if (data_info.total > 0) {
            const totalPage = Math.ceil(data_info.total / limit_data)
            settotal(data_info.total)
            setTotalPages(totalPage)
            const ThisSkip = data_info.skip == 0 ? 1 : (data_info.skip / limit_data) + 1;

            const newTableCellData = data_info.data.map((v, index) => ({
                no: limit_data * ThisSkip - (9 - index),
                user_no: (v.user_no && permission_ActivityActivityMember_View?.canRead == true) ? (
                    <div className="text-bold link" onClick={() => OpenViewModal(v)}>
                      {v.user_no }
                    </div>
                ) : (
                  <div className="text-bold">
                    {v.user_no }
                  </div>
                ),
                full_name: v.full_name ? v.full_name : '-',
                activity_name: v.activity_name ? DisplayTableActivityDescription(v.activity_name) : '-',
                engineering_field_name: v.engineering_field_name ? v.engineering_field_name : '-',
                organizations_name : v.organizations_name ? v.organizations_name : '-',
                ref_activity: (
                    v.ref_activity ? 
                    <div
                      className="status-badge"
                      style={{
                        color: v.ref_activity_status_badge_color?.color,
                        borderColor: v.ref_activity_status_badge_color?.border,
                        backgroundColor: v.ref_activity_status_badge_color?.background,
                      }}
                    >
                      {v.ref_activity}
                    </div>
                    : 
                    <div >-</div>
                ),
                createdAt: v.createdAt ? DateDisplayDDMMBBBHHmmss(v.createdAt) : '-',  
                user_point: v.user_point ? v.user_point : '-',
                effective_date: v.effective_date_full ? DateDisplayDDMMBBBHHmmss(v.effective_date_full) : '-',
                expire_date: v.expire_date ? DateDisplayDDMMBBB(v.expire_date) : '-',
                status_name: (
                    v.status_name ? 
                    <div
                      className="status-badge"
                      style={{
                        borderColor: v.status_badge_color?.border,
                        backgroundColor: v.status_badge_color?.background,
                      }}
                    >
                      <GrStatusGoodSmall 
                        className="status"
                        style={{
                          color: v.status_badge_color?.color,
                        }}
                      />
                      {v.status_name}
                    </div>
                    : 
                    <div >-</div>
                ),
                cancle_data: permission_ActivityActivityMember_Cancle?.canRead == true ? (
                    <ButtonSecondary className="button_cancle_data"  onClick={() => CancleData(v)}>
                      ยกเลิกกิจกรรม
                    </ButtonSecondary>
                ) : (''),    
            }))
            setTableCellData(newTableCellData)
          }else{
            settotal(0);
            setPage(0);
            setTotalPages(0);
            setTableCellData([]);
          }
         
        }
      })

    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter) //-- ปิด รอ API
  }, [fetchData, limit_data])


  const onChangePaginationAttendeeAttend = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data, 
      limit: limit_data,
      name : Filter.name,
      ref_activity : Filter.ref_activity,
      effective_date : Filter.effective_date,
    }
    fetchData(filter)
    setPage(value.page)
  }
  const onClearfilterName = () => {
    setFilter({ ...Filter, name: '' })
  }

  const onSearch = () => {
    const filter = {
      skip: 0,
      limit: limit_data,
      name : Filter.name,
      ref_activity : Filter.ref_activity,
      effective_date : Filter.effective_date,
    }
    fetchData(filter)
    setPage(1)
  }
  const onClearSearch = () => {
    setFilter({
        name : '',
        ref_activity : '',
        effective_date : null,
    })
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter)
    setPage(1)
  }
  const OpenViewModal = (Obj) => {
    navigate('/Activity/ActivityMemberInfo/' + Obj.user_no)
  }

  const [UserActivityPointId, setUserActivityPointId] = useState(null)
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ModalConfirmDialogDel, setModalConfirmDialogDel] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const CancleData = (data) => {
    setUserActivityPointId(data.id)
    setConfirmMessageModal({
        headline: 'กรุณายืนยันการยกเลิกกิจกรรม',
        message: '',
    })
    setModalConfirmDialogDel(true);
  }

  const ApiProcess = (value) => {
    setModalConfirmDialogDel(false);
    const request = {
      status_id: 2 ,
      remark: value,
    }
    dispatch(ApiUpdateUserActivityPoint(UserActivityPointId, request)).then(({ type,data_info }) => { 
      if(type.endsWith('_SUCCESS')){
        setSuccessMessageModal({
          headline: 'ยกเลิกกิจกรรมสำเร็จ',
          message: '',
        })
        setSuccessModal(true)
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          message: '',
        })
        setFailModal(true)
      }
      
    })
  }

  return (
    <Div>
        <div className="box_header">
            <div>
                <div className="content-title">
                    ประวัติกิจกรรมของสมาชิก
                </div>
                <div className="content-title-text">
                    สำหรับเจ้าหน้าที่ตรวจสอบประวัติการทำกิจกรรมของสมาชิก
                </div>
            </div>
        </div>
        <div className="css_magin_top_16"></div>
        <div className="border_header"></div>
        <div className="css_magin_top_16"></div>
        <DivFilter>
          <div className="content-fillter">
            <div className="group_filter">
              <div className="css_filter_name">
                <Field className="field" label="ค้นหา">
                  <SearchText
                    placeholder="Search เลขที่สมาชิก ชื่อนามสกุล หรือชื่อกิจกรรม"
                    value={Filter.name}
                    onChange={(e) => setFilter({ ...Filter, name: e.target.value })}
                    onClear={onClearfilterName}
                  />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="ประเภทที่มาของคะแนน">
                  <Dropdown
                      id={`dd_filter`}
                      className="dd_filter"
                      value={Filter.ref_activity}
                      optionList={RefActivityOption}
                      onChange={(v) => setFilter({ ...Filter, ref_activity: v })}
                      placeHolder={'เลือก'}
                  />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="วันที่เข้าร่วมกิจกรรม">
                    <Date
                        className={'input-calendar'}
                        value={Filter.effective_date}
                        onChange={(v) => setFilter({ ...Filter, effective_date: v })}
                    />
                </Field>
              </div>
              <div className="css_button_filter mr-1-rem">
                  <Button onClick={onSearch} >
                    ค้นหา
                  </Button>
              </div>
              <div className="css_button_filter mr-1-rem">
                <ButtonSecondary onClick={onClearSearch} >
                  ล้างการค้นหา
                </ButtonSecondary>
              </div>
            </div>
          </div>
        </DivFilter>
        <div className="css_magin_top_16"></div>
        <div className="table-wrapper">
          <div className="table-header">
            <div className="group">
              <div className="table-title">รายการกิจกรรม</div>
              <div className="table-total">
                {total} รายการ
              </div>
            </div>
          </div>
          <div className="css_magin_top_10">
            <TableV2
              columns={TableColumnData}
              data={TableCellData}
              onStateChange={onChangePaginationAttendeeAttend}
              pageCount={totalPages}
              page={page}
              loading={false}
            />
          </div>
        </div>
        <Modal
          open={Boolean(ModalConfirmDialogDel)}
          onClose={() => setModalConfirmDialogDel(false)}
          disableBackdropClick
          width={'400px'}
        >
          <ConfirmCancelActivityModalContent 
            onClose={() => setModalConfirmDialogDel(false)} 
            onSubmit={ApiProcess}
          />
        </Modal>
          {
            /*
            <DialogConfirmV2
              open={Boolean(ModalConfirmDialogDel)}
              onClose={() => setModalConfirmDialogDel(false)}
              onNo={() => setModalConfirmDialogDel(false)}
              onSubmit={() => {
                setModalConfirmDialogDel(false)
                ApiProcess()
              }}
              icon={warningIcon}
              title={ConfirmMessageModal.headline}
              nameItem={ConfirmMessageModal.message}
              textYes='ยืนยัน'
              textNo='ยกเลิก'
            />
            */
          }


      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
          onClearSearch()
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
          onClearSearch()
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />
    </Div>
  )
}

export default ActivityMember
